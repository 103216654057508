<template>
  <div class="register">
    <div>
      <img src="../assets/splash.png" alt="" class="logo">
      <van-form @submit="onSubmit" validate-trigger="onBlur">
        <field class="fieldBox" v-model="loginName" left-icon="user-circle-o" name="Login Name" label=""
          placeholder="Login Name" :rules="[{ validator: formCheckName, message: () => { return nameMsg } }]" />
        <field class="fieldBox" type="number" v-model="phoneNumber" name="Phone Number" left-icon="phone-circle-o"
          placeholder="Phone Number" :rules="[{ required: true, message: 'Please input your phone number' }]">
          <template #button>
            <van-button type="primary" @click="sendVerificationCode" class="codeBtn" native-type="button">{{ codeTime >
              0 ? `${codeTime} s` : 'Send OTP'}}</van-button>
          </template>
        </field>

        <field class="fieldBox" type="number" v-model="verificationCode" name="Verification Code" left-icon="comment-circle-o"
          placeholder="Verification Code"
          :rules="[{ required: true, message: 'Please input your phone verificationCode' }]" />
        <field class="fieldBox" v-model="password" :type="passwordType" name="Password" ref="password"
          left-icon="browsing-history-o" :right-icon="rightIcon" @click-right-icon="changePass" placeholder="Password"
          :rules="[{ message: 'Password must be at least 6 characters', pattern: /^.{6,}$/ }]" />

        <field class="fieldBox" v-model="inviteCode" name="inviteCode Code" label="Invite Code" label-width="120px"
          placeholder="invite Code" />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit" class="submitBtn">Create</van-button>
        </div>
      </van-form>
    </div>
    <div v-show="loading" class="loadingBox">
      <Loading type="spinner"></Loading>
    </div>
  </div>
</template>

<script>
import { register, checkName, sendCode } from "@/api/user.js";
import { Button as VanButton, Form as VanForm, Field, Toast, Loading } from 'vant';

export default {
  name: 'Register',
  components: {
    VanButton,
    VanForm,
    Field,
    Loading
  },
  data() {
    return {
      rightIcon: 'closed-eye',
      loading: false,
      passwordType: 'password',
      loginName: '',
      nameMsg: '',
      phoneNumber: '',
      verificationCode: '',
      password: '',
      inviteCode: '',
      lastTrueName: '',
      currentId: '',
      codeTime: 0
    };
  },
  created() {
    // 从地址栏取出邀请人ID
    this.inviteCode = this.$route.query.inviteCode;
    console.log('邀请人code', this.inviteCode)
  },
  methods: {
    onSubmit(values) {
      this.loading = true;
      // console.log('submit', values);
      // 发送请求
      register({
        loginName: this.loginName,
        phoneNumber: this.phoneNumber,
        verificationCode: this.verificationCode,
        password: this.password,
        currentId: this.currentId,
        inviteCode: this.inviteCode
      }).then(res => {
        if (res.id && res.id > 0) {
          Toast(res.msg);
          // 跳转到下载页面
          this.$router.push('/download');
        } else {
          Toast(res.msg);
        }
        this.loading = false;
      }).catch(err => {
        console.log(err);
        this.loading = false;
      });
      // Handle form submission logic here
    },
    changePass() {
      this.rightIcon = this.rightIcon === 'closed-eye' ? 'eye-o' : 'closed-eye';
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    },
    isValidLoginName(inputString) {
      // Define the regular expression for letters and numbers only
      const regex = /^[a-zA-Z0-9]+$/;
      // Check if the string matches the pattern and has a minimum length of 4 characters
      return regex.test(inputString) && inputString.length >= 4;
    },
    // 查询名字是否存在
    formCheckName(val) {
      // 判断
      if (!val) {
        this.nameMsg = 'Please input your login name'
        return false;
      }
      if (!this.isValidLoginName(val)) {
        this.nameMsg = 'LOGIN NAME MUST BE AT LEAST 4 CHARACTERS AND ONLY LETTERS AND NUMBERS';
        // this.$toast('LOGIN NAME MUST BE AT LEAST 4 CHARACTERS AND ONLY LETTERS AND NUMBERS');
        return false;
      }
      // 目的：无感验证名字是否存在，减少请求次数，因为提交的时候还会验证一次
      if (val === this.lastTrueName) {
        this.nameMsg = '';
        return true;
      }
      // this.loading = true;
      return checkName(val).then(res => {
        if (res.code === 200 && !res.data) {
          this.nameMsg = `LOGIN USER ${val} HAS BEEN USED`;
          // this.loading = false;
          return false;
        } else {
          this.nameMsg = '';
          this.lastTrueName = val;
          // this.loading = false;
          return true;
        }
      }).catch(err => {
        // this.loading = false;
      });
    },
    sendVerificationCode() {
      if (this.codeTime > 0) {
        return;
      }
      // Handle sending verification code logic here
      if (!this.phoneNumber) {
        Toast("Please input your phone number");
        return;
      }
      if (!this.loginName) {
        Toast('LOGIN NAME IS REQUIRED');
        return;
      }
      this.sendPhoneCode();
    },
    // 发送验证码
    sendPhoneCode() {
      this.loading = true;
      sendCode({
        phoneNumber: this.phoneNumber,
        loginName: this.loginName
      }).then(res => {
        if (!res.msg) {
          Toast('Verification code sent successfully');
          this.currentId = res.id.toString();
          // 更改按钮状态
          this.codeTime = 60;
          const timer = setInterval(() => {
            this.codeTime--;
            if (this.codeTime <= 0) {
              clearInterval(timer);
            }
          }, 1000);
        } else if (res.msg === 'User has registered') {
          Toast('User has registered');
          // 用户名清空
          this.loginName = '';
          // 触发提示
          this.formCheckName(this.loginName);
        } else {
          Toast(res.msg);
        }
        this.loading = false;
      }).catch(err => {
        this.loading = false;
      });
    }
  }
};
</script>

<style scoped>
.register {
  margin: 0 auto;
  width: 100%;
  padding: 0 5vw;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 2.5vw;
  border: 0.1vw solid #ccc;
  border-radius: 1vw;
}

button {
  padding: 2.5vw 5vw;
  background-color: #EC1C24;
  color: #fff;
  border: none;
  border-radius: 1vw;
  cursor: pointer;
}

button:hover {
  background-color: #EC1C24;
}

.logo {
  /* width: 100px; */
  height: 40vw;
  margin: 0 auto;
  display: block;
  margin-top: 5.33vw;
  margin-bottom: 16vw;
}

.fieldBox {
  /* line-height: 12vw; */
  margin-top: 10px;
  padding-bottom: 14px;
}

.codeBox {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.codeBtn {
  width: 30vw;
  height: 8vw;
  text-align: center;
  font-size: 3vw;
  border-radius: 1vw;
  background-color: #EC1C24;
  color: #fff;
  cursor: pointer;
}

.submitBtn {
  height: 10.67vw;
  font-size: 4.53vw;
  border-radius: 1vw;
  background-color: #EC1C24;
  color: #fff;
  cursor: pointer;
  margin-top: 13.33vw;
}

::v-deep .van-field__left-icon i {
  font-size: 6.67vw;
  margin-right: 4vw;
}

.loadingBox{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
</style>
