import Vue from 'vue';
import VueRouter from 'vue-router';
import Register from '../views/register.vue';
import Download from '../views/download.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', redirect: '/register' },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/download',
    name: 'Download',
    component: Download
  }
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
