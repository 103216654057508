//api方法封装
import axios from 'axios';
import qs from "qs";


axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASE_API;


//  注册
export function register(data) {
  const url = '/mobileAuth/register'
  return axios.post(url, data, {
    headers: {
    'Content-Type': 'application/json'
    }
  }).then((res) => {
    return Promise.resolve(res.data)
  })
}

//  发送手机验证码
export function sendCode(data) {
  const url = '/mobileAuth/submit'
  return axios.post(url, data, {
    headers: {
    'Content-Type': 'application/json'
    }
  }).then((res) => {
    return Promise.resolve(res.data)
  })
}

// 查询名字是否存在
export function checkName(name) {
  const url = '/mobileAuth/loginName/'
  return axios.get(url + name).then((res) => {
    return Promise.resolve(res.data)
  })
}