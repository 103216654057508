<template>
  <div class="bg">
    <div class="header">
      <img src="../assets/bg.png" alt="">
    </div>
    <img src="../assets/CompanyLogo.png" alt="">
    <p class="downloadText">Download Our App</p>
    <div class="btnBox">
      <a href="https://play.google.com/store/apps/details?id=com.upfue.teamautocare" target="_blank" class="btn">
        <img src="../assets/android.png" alt="Android" class="img" /> Download on the Play Store
      </a>
      <a href="https://apps.apple.com/sg/app/teamautocareapp/id6502876959" target="_blank" class="btn">
        <img src="../assets/apple.png" alt="iOS" class="img" /> Download on the App Store
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Download',
}
</script>

<style>
/* Add your custom styles here */
.bg{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f8f8f8;
}
.header{
  height: 60vw;
  width: 100%;
  background: #EC1C24;
  position: relative;
}
.header img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  vertical-align: bottom;
}
.downloadText{
  font-size: 20px;
  margin-top: 10vw;
  font-weight: 600;
}
.btnBox{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10vw;
}
.btn{
  display: block;
  background: #EC1C24;
  padding: 4vw 5vw;
  color: #fff;
  width: 80%;
  margin-top: 12vw;
  font-size: 14px;
  border-radius: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img{
  width: 5vw;
  height: 5vw;
  margin-right: 2vw;
}
</style>