<template>
  <div id="app">
    <div class="page_warp">
    	<router-view ></router-view>
  	</div>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
.page_warp{
  position: absolute;
  top:0;
  left:0;
  right: 0;
  width:100%;
  bottom:  0;
  background: #fff;
  z-index: 3;
  height: 100%;
  overflow:hidden;
}
</style>
